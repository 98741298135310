
::v-deep .el-breadcrumb{
  .el-breadcrumb__item{
    .el-breadcrumb__inner{
      color: #999999;
    }
    &:last-of-type{
      .el-breadcrumb__inner{
        color: #333;
      }
    }
  }
  .el-breadcrumb__separator{
    color: #999;
    font-weight: 500;
  }
}
.main-wrapper{
  height: 100%;
  display: flex;
  flex-direction: column;

  .main-scrollbar{
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
      .el-scrollbar__view{
        padding: 0 20px;
      }
    }
  }
  .main-wrapper-header{
    padding: 0px 0 14px;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #F1F5FF;
    .header-left{
      display: flex;
      align-items: center;
      span{
        line-height: 1;
      }
    }
  }
}
